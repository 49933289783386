<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  .log-tips {
    margin-top: 80px;
  }

  .login-main {
    padding: 0 91px;
  }

  .tabs {
    width: 330px;
  }

  .log-r .input-group {
    width: 100%;
  }

  .form-control {
    padding: unset;
  }

  /deep/ .el-input__inner {
    border: none;
    height: 100%;
    line-height: 0;
  }
</style>
<script>
  // import {
  //   required,
  //   email
  // } from "vuelidate/lib/validators";
  import LoginLeft from "@/views/pages/account/login-left";
  import LoginTop from "@/views/pages/account/login-top";
  import MessageModal from "@/components/codeVerification/messageModal.vue";

  import MessageCodeModal from "@/components/codeVerification/messageCodeModal.vue";
  import {
    getNowExam
  } from "@/api/admin/exam/examRecord.js"
  import {
    userLogin,
    phoneLogin,
    listenEnterEv,
    sendPhoneCode,
    initSystem,
    loginJump,
    getcode,
    checkCode,
  } from "@/api/index.js"
  import {
    setStore
  } from "@/libs/storage.js"
  import Verify from "@/components/verifition/Verify";

  // import {
  //   authMethods,
  //   authFackMethods,
  //   notificationMethods
  // } from "@/state/helpers";

  export default {
    components: {
      LoginLeft,
      LoginTop,
      Verify,
      MessageCodeModal,
      MessageModal
    },
    data() {
      return {
        isPasswordLogin: true,
        committing: false,
        committingPhoneCode: false,
        isLogout: "",
        showmodal: false,
        userPhone: "",
        baseConfig: {
          SystemYear: "",
          WebsiteName: ""
        },
        rules: {
          username: [{
              required: true,
              message: '请输入用户名/身份证号',
              trigger: 'blur'
            }

          ],
          password: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }]
        },
        rulesTel: {
          mobilePhone: [{
              required: true,
              message: '请输入手机号',
              trigger: 'blur'
            },
            {
              min: 11,
              max: 11,
              message: '长度为11位数字',
              trigger: 'blur'
            }
          ],
          phoneCode: [{
            required: true,
            message: '请输入手机验证码',
            trigger: 'blur'
          }]

        },
        mmqd: 0,
        showImageCheck: false,
        //form 提交
        formDataUsername: {
          username: "",
          password: "",
          uuid: '',
          captchaVO: ""
        },
        formDataTel: {
          mobilePhone: '',
          phoneCode: '',
          captchaVO: "",
          uuid: ''
        },
        //手机号码验证
        isPhone: false,
        phoneCheckCode: false,
        loginType: 'account',
      }
    },
    created() {
      // 获取
      initSystem().then(res => {
        if (res.status) {
          this.baseConfig = res.data;
          localStorage.setItem('BaseConfig', JSON.stringify(res.data))
        }
      });
      this.getCookie()

      // // 判断是否机构用户
      // var userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
      // if (userInfo && !(userInfo.roleNum == 3 || userInfo.roleNum == 2)) {
      //   loginJump(this, userInfo)
      // }

    },

    methods: {

      success(params) {
        this.committing = true;
        // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
        // this.$router.push('/admin');
        this.formDataUsername.captchaVO = JSON.stringify(params);
        this.formDataTel.captchaVO = JSON.stringify(params);
        // this.checkLogin();
        console.log(this.isPasswordLogin);
        
        var form = this.isPasswordLogin ? "formDataUsername" : "formDataTel";
        if (this.isPasswordLogin) {
          userLogin(this[form]).then(res => {
            this.handlerMsg(res);
          })
        } else {
          phoneLogin(this[form]).then(res => {
            this.handlerMsg2(res);
          })
        }
        this.committing = false;
      },
      // 滑动验证显示
      useVerify() {
        this.$refs.verify.refresh();
        this.$refs.verify.show()
      },
      handlerMsg2(msg){
        if (msg.status) {
          setStore("accessToken", msg.data.token);
          setStore("userInfo", msg.data);
          this.$store.commit('setUserInfo',msg.data)
          loginJump(this, msg.data)

        }
      },
      handlerMsg(msg) {
        if (msg.status) {
          // 正确
          let userInfo = msg.data
          this.mmqd = msg.data.mmqd
          this.userPhone = msg.data.yddh ? msg.data.yddh : ""
          // 机构用户必走修改手机号
          if (userInfo.roleNum == 3 || userInfo.roleNum == 2) {
            if (this.$route.name != 'userData' &&
              !this.userPhone) {
              this.$confirm('您还未填写电话，为了您的账号安全请及时维护，立即前往维护?', '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                type: 'warning'
              }).then(() => {
                setStore("accessToken", msg.data.token);
                setStore("userInfo", msg.data);
                this.$store.commit('setUserInfo',msg.data)
                window.location.href = '/home'
                // 获取当前考试
                getNowExam().then(() => {})
                return
              })
            } else if (this.userPhone) {
              this.orgLogin(msg)
              return
            }
          } else { //管理员
            // 如果没有管理员手机号也可以直接登
            if (this.userPhone) {
              this.orgLogin(msg)
              return
            } else {
              setStore("accessToken", msg.data.token);
              setStore("userInfo", msg.data);
              this.$store.commit('setUserInfo',msg.data)
              // 获取当前考试
              getNowExam().then(() => {})
              if (this.mmqd == 0) {
                this.$router.push("./weekPassword")
                return
              }
              loginJump(this, msg.data)
            }

          }


        } else {
          // 错误
          // _this.imgCodeChange();
        }
        this.committing = false;
      },
      // 机构用户发送验证码
      orgLogin(msg) {
        let userInfo = msg.data
        if (userInfo.yhjs) {
          let message = userInfo.yhjs.split("|")[0]
          this.userPhone = userInfo.yhjs.split("|")[1]
          if (message == "已发送短信验证码") {
            this.showmodal = true
            this.$refs.messageCode.resetCode(60); //倒计时
            this.$notify({
              title: '成功',
              message: '系统已自动通过您预留的手机号发送验证短信码，30分钟内有效且可重复使用，请注意查收',
              type: 'success'
            });
            return
          } else {
            this.showmodal = false
          }

        }
      },
      // 登录按钮点击后触发的事件 通过 isPasswordLogin 判断使用什么方式进行登录
      checkLogin: function() {
        this.isPasswordLogin = this.loginType != 'tel'

        // 无论是电话号码验证还是账户密码验证都走这个处理函数
        this.setCookie(this.formDataUsername.username, 7);
        var form = this.isPasswordLogin ? "formDataUsername" : "formDataTel";
        this.$refs[form].validate(valid => {
          if (valid) {
            this.useVerify();
          }
        })
      },


      //验证手机号码
      checkPhone: function() {
        var pattern = /^1[0-9]{10}$/;
        this.isPhone = true;
        if (this.formDataTel.mobilePhone === '') {
          this.$notify({
            title: '失败',
            message: '请输入手机号码',
            type: 'error'
          });
          this.isPhone = false;
          return this.isPhone;
        }
        if (!pattern.test(this.formDataTel.mobilePhone)) {
          this.$notify({
            title: '失败',
            message: '请输入正确的手机号码',
            type: 'error'
          });
          this.isPhone = false;
          return this.isPhone;
        }
      },

      // 设置cookie
      setCookie(name, exdays) {
        var exdate = new Date() // 获取时间
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
        // 字符串拼接cookie
        window.document.cookie = 'username' + '=' + name + ';path=/;expires=' + exdate.toGMTString()
      },

      // 读取cookie 将用户名和密码回显到input框中
      getCookie() {
        if (document.cookie.length > 0) {
          var arr = document.cookie.split('; ') // 这里显示的格式需要切割一下自己可输出看下
          for (var i = 0; i < arr.length; i++) {
            var arr2 = arr[i].split('=') // 再次切割
            // 判断查找相对应的值
            if (arr2[0] === 'username') {
              this.formDataUsername.username = arr2[1] // 保存到保存数据的地方
            }
          }
        }
      },
      // 切换登录
      changeLogin(data) {
        var _this = this;
        this.loginType = data;
        if (data != "tel") {
          _this.isPasswordLogin = true;
          return
        }
        _this.isPasswordLogin = false
      },
      getCode(){
        this.$refs.messageModal.isCodeExist(this.formDataTel.mobilePhone)

      }
    },

    mounted() {
      listenEnterEv(this.checkLogin(this.loginType));
      // listenEnterEv(this.checkPhone);
    }
  };
</script>

<template>
  <div>
    <Verify @success="success" mode="pop" captchaType="blockPuzzle" :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"></Verify>
    <div class="login-header">
      <div class="wp">
        <div class="p13">
          <LoginTop />
          <div class="login-bottom mt-4 d-flex">
            <LoginLeft />

            <div class="log-r">
              <p class="log-tips">
                用户登录
              </p>
              <div class="mt-4 login-main">
                <b-tabs justified nav-class="nav-tabs-custom" content-class="pt-3 text-muted">
                  <b-tab active>
                    <template v-slot:title>
                      <span class="d-none d-sm-inline-block" @click="changeLogin('account');">使用账号密码登录</span>
                    </template>
                    <el-form :model="formDataUsername" :rules="rules" ref="formDataUsername">
                      <el-form-item prop="username">
                        <div class="input-group">
                          <div class="input-group-prepend "><span class="input-group-text icon-box"><img
                                src="@/assets/images/person/icon/user.png" alt=""></span></div>
                          <el-input type="text" placeholder="请输入用户名/身份证号" v-model="formDataUsername.username"
                            aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100" />
                        </div>
                      </el-form-item>
                      <el-form-item prop="password">
                        <div class="input-group ">
                          <div class="input-group-prepend"><span class="input-group-text icon-box"><img
                                src="@/assets/images/person/icon/lock.png" alt=""></span></div>
                          <el-input type="password" placeholder="请输入登录密码" v-model="formDataUsername.password"
                            aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100" />
                        </div>
                      </el-form-item>
                      <!-- <el-form-item prop="code">
                        <div class="input-group">
                          <button @click="useVerify" type="button"  class="btn btn-light rounded-pill w-100" >点击获取验证码</button>
                        </div>

                        <div class="input-group ">
                          <div class="input-group-prepend"><span
                              class="input-group-text icon-box"><img src="@/assets/images/person/icon/security.png"
                                alt=""></span></div><input v-model="formDataUsername.code" type="text"
                            placeholder="请输入验证码" aria-describedby="validationTooltipUsernamePrepend"
                            class="form-control h-100">
                          <img :src="imageCodeUrl" @click="imgCodeChange" class="check-img">
                        </div>
                      </el-form-item> -->
                      <div class="text-center">
                        <button type="button" class="btn btn-info mr-2 w-xl" @click="checkLogin('account')"><template
                            v-if="committing">
                            登录中
                            <i class="el-icon-loading"></i>
                          </template>
                          <template v-else>
                            登录系统 </template>
                        </button>
                        <a href="/register"><button type="button" class="btn btn-secondary w-xl">注册账号</button></a>
                      </div>
                      <div class="login-span mt-3 text-center">
                        <a href="/forgot-password"><span>找回密码</span></a>
                        <span class="ml-2">|</span>
                        <a href="/find-password"><span>账号赎回</span></a>
                        <span class="ml-2">|</span>
                        <a href="/browser"><span>浏览器要求</span></a>
                        <!-- <a href="browser.html"><span>浏览器要求</span></a> -->
                      </div>
                    </el-form>
                  </b-tab>

                  <b-tab >
                    <template v-slot:title>
                      <span class="d-none d-sm-inline-block" @click="changeLogin('tel')">使用短信验证码登录</span>
                    </template>
                    <el-form :model="formDataTel" :rules="rulesTel" ref="formDataTel">
                      <el-form-item prop="mobilePhone">
                        <div class="input-group ">
                          <div class="input-group-prepend"><span class="input-group-text icon-box"><img
                                src="@/assets/images/person/icon/phone.png" alt=""></span></div>
                          <el-input type="text" placeholder="请输入手机号码" id="phone" autocomplete="on"
                            v-model="formDataTel.mobilePhone" aria-describedby="validationTooltipUsernamePrepend"
                            class="form-control h-100" />
                        </div>
                      </el-form-item>

                      <el-form-item prop="phoneCode">
                        <div class="input-group ">
                          <div class="input-group-prepend"><span class="input-group-text icon-box"><img
                                src="@/assets/images/person/icon/sms.png" alt=""></span></div>
                          <el-input type="text" placeholder="请输入短信码" v-model="formDataTel.phoneCode"
                            aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100" />
                          <button type="button" id="code_button" class="btn btn-info h-100 verification-code"   @click="getCode">获取短信码</button>
                        </div>
                      </el-form-item>
                      <!-- <el-form-item prop="code">
                        <div class="input-group">
                          <button @click="useVerify" type="button" class="btn btn-light rounded-pill w-100">点击获取验证码</button>

                        </div>
                        <div class="input-group ">
                          <div class="input-group-prepend"><span
                              class="input-group-text icon-box"><img src="@/assets/images/person/icon/security.png"
                                alt=""></span></div><input v-model="formDataTel.code" type="text" placeholder="请输入验证码"
                            aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100">
                          <img :src="imageCodeUrl" @click="imgCodeChange" class="check-img">
                        </div>
                      </el-form-item> -->
                      <div class="login-btn">
                        <button type="button" class="btn btn-info  w-100" @click="checkLogin('tel')">登录系统</button>
                      </div>
<!--                      <div class="login-span mt-3 text-center">-->
<!--                        <span class="text-danger">说明：短信码登录仅对单位用户开放</span>-->
<!--                      </div>-->
                    </el-form>
                  </b-tab>

                </b-tabs>


              </div>
            </div>



          </div>
          <div class="login-footer text-center mt-4">
            版权所有：{{baseConfig.WebsiteName}}
<!--            © {{baseConfig.SystemYear}} Rights Reserved-->
          </div>
        </div>



      </div>
    </div>
    <!-- 弹窗开始-->
    <MessageCodeModal ref="messageCode" v-model="showmodal" :userPhone="userPhone" :mmqd="mmqd"></MessageCodeModal>
    <MessageModal ref="messageModal"  :userPhone="formDataTel.mobilePhone" :mmqd="mmqd"></MessageModal>

    <!-- 获取短信验证 -->
    <!--   <b-modal id="register" v-model="showmodal" title="获取验证码" title-class="font-18" centered class="person-modal"
      hide-footer>
      <div class="input-group" style="height: 45px">
        <div class="input-group-prepend">
          <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
              src="@/assets/images/person/icon/security.png" alt="" /></span>
        </div>
        <el-input id="code" type="text" placeholder="请输入验证码" class="form-control h-100" v-model="userPhoneCode" />
        <button type="button" id="code_button" class="btn btn-info h-100 verification-code"
          @click="getCodeAgain">获取短信码</button>
      </div>
      <div class="text-center mt-2 h40" style="clear: both;"><button type="button" class="btn btn-info w-sm"
          @click="userPhoneLogin">确定</button></div>
    </b-modal> -->


    <!-- 算式验证码 -->
    <!-- <b-modal id="imgCheck" v-model="isShow" title="算式验证" title-class="font-18" centered class="person-modal"
      hide-footer>
      <div class="input-group" style="height: 45px">
        <div class="input-group-prepend">
          <span class="input-group-text icon-box"><img src="@/assets/images/person/icon/security.png" alt="" /></span>
        </div>
        <el-input id="code" type="text" placeholder="请输入算式验证码" class="form-control h-100" v-model="verification" />
        <img :src="imgCode.img" class="check-img" @click="getImageCode" />
      </div>
      <div class="w-100 mt-2">
        <button type="button" class="btn btn-info change-btn" @click="checkImagecode">
          获取短信验证码
        </button>
      </div>
    </b-modal> -->
    <!-- 弹窗结束-->
  </div>
</template>
